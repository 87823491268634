import React from "react"; // Importa a biblioteca React
import { Box, Typography, IconButton, Link } from "@mui/material"; // Importa componentes da biblioteca @mui/material
import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // Importa o ícone de "curtir" preenchido
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined"; // Importa o ícone de "curtir" contornado
import DeleteIcon from "@mui/icons-material/Delete"; // Importa o ícone de "excluir"
import axios from "axios"; // Importa a biblioteca axios para fazer requisições HTTP
import configUrl from "../config/configUrl"; // Importa a URL de configuração da aplicação

// Define o componente funcional SuggestionList
const SuggestionList = ({ suggestions, onVote, fetchSuggestions }) => {
  // Define o estado local para armazenar as sugestões curtidas
  const [likedSuggestions, setLikedSuggestions] = React.useState([]);

  // Função assíncrona para lidar com a exclusão de sugestões
  const handleDelete = async (id) => {
    try {
      // Faz uma requisição DELETE para excluir a sugestão com o id fornecido
      await axios.delete(`${configUrl.apiUrl}/api/suggestions/${id}`);
      // Atualiza a lista de sugestões após a exclusão
      fetchSuggestions();
    } catch (error) {
      // Loga o erro no console em caso de falha na exclusão
      console.error("Error deleting suggestion:", error);
    }
  };

  // Função assíncrona para lidar com a votação (curtir/descurtir) de sugestões
  const handleVote = async (id) => {
    // Verifica se a sugestão já foi curtida
    if (likedSuggestions.includes(id)) {
      // Se já foi curtida, faz uma requisição POST para remover a curtida
      try {
        await axios.post(`${configUrl.apiUrl}/api/suggestions/${id}/unvote`);
        // Atualiza o estado removendo a sugestão curtida
        setLikedSuggestions((prevLikedSuggestions) =>
          prevLikedSuggestions.filter((suggestionId) => suggestionId !== id)
        );
        onVote(); // Chama a função onVote para atualizar a lista de sugestões
      } catch (error) {
        // Loga o erro no console em caso de falha ao remover a curtida
        console.error("Error decrementing vote count:", error);
      }
    } else {
      // Se não foi curtida, faz uma requisição POST para adicionar a curtida
      try {
        await axios.post(`${configUrl.apiUrl}/api/suggestions/${id}/vote`);
        // Atualiza o estado adicionando a sugestão curtida
        setLikedSuggestions((prevLikedSuggestions) => [
          ...prevLikedSuggestions,
          id,
        ]);
        onVote(); // Chama a função onVote para atualizar a lista de sugestões
      } catch (error) {
        // Loga o erro no console em caso de falha ao adicionar a curtida
        console.error("Error incrementing vote count:", error);
      }
    }
  };

  // Renderiza a lista de sugestões
  return (
    <Box sx={{  width: {xs:"100%", sm:"80%"}, mt:4}}>
      {/* Itera sobre a lista de sugestões para renderizar cada uma */}
      {suggestions.map((suggestion) => (
        <Box
          key={suggestion.id} // Define a chave única para cada sugestão
          sx={{ mb: 2, p: 2, borderBottom: "1px solid #ccc", borderLeft: "1px solid #ccc", borderRadius: 5, width: "100%"}}
        >
          {/* Seção de cabeçalho da sugestão */}
          <Box sx={{ display: "flex", justifyContent: 'space-between', borderBottom: "1px solid #ccc"  }}>
            <Typography variant="h6" fontSize="1em" color="primary.main" paddingRight={"10px"} >
              @{suggestion.name} {/* Nome do autor da sugestão */}
            </Typography>
            <Link
              href={`https://solscan.io/tx/${suggestion.txs}`} // Link para visualizar a doação
              target="_blank" // Abre o link em uma nova aba
              rel="noopener noreferrer" // Adiciona segurança ao link
              underline="hover"
              fontSize={'0.8em'}
              color={"primary.main"}
            >
              View the donation {/* Texto do link */}
            </Link>
          </Box>

          {/* Seção de conteúdo da sugestão */}
          <Box sx={{ display: "flex", alignItems: "top", justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="body2" sx={{ mr: 2 }}>{suggestion.suggestion}</Typography> {/* Texto da sugestão */}
            <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "space-evenly" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => handleVote(suggestion.id)}>
                  {/* Condicional para exibir o ícone correto (curtido ou não curtido) */}
                  {likedSuggestions.includes(suggestion.id) ? (
                    <ThumbUpIcon /> // Ícone de curtido
                  ) : (
                    <ThumbUpAltOutlinedIcon /> // Ícone de não curtido
                  )}
                </IconButton>
                <Typography variant="body2">{suggestion.votes}</Typography> 
              </Box>
              {/* <IconButton
                size="small"
                color="error"
                onClick={() => handleDelete(suggestion.id)} // Função de exclusão ao clicar no ícone de lixeira
              >
                <DeleteIcon /> 
              </IconButton>  */}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

// Exporta o componente SuggestionList como padrão
export default SuggestionList;
