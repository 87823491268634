import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FileUpload = ({ onFileChange, filePreview, error }) => {
  const [previewType, setPreviewType] = useState("");

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const selectedFile = event.target.files[0];
    const fileType = file.type.startsWith("video/") ? "video" : "image";
    setPreviewType(fileType);
    onFileChange(selectedFile);
  };

  return (
    <Box
      sx={{
        width:"100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent:"center",
        justifyContent:"center",
        gap: 2,
      }}
    >
      {filePreview && (
        <>
          {previewType === "image" ? (
            <Box
              component="img"
              src={filePreview}
              sx={{
                width: 200,
                height: 200,
                objectFit: "cover",
                borderRadius: 5,
                mt: 2,
              }}
            />
          ) : (
            <Box
              component="video"
              src={filePreview}
              controls
              sx={{
                width: 200,
                height: 200,
                borderRadius: 5,
                mt: 2,
              }}
            />
          )}
        </>
      )}
      <Button
        component="label"
        role={undefined}
        variant="outlined"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Select File
        <input
          type="file"
          hidden
          onChange={handleChange}
          accept="image/png, image/jpeg, image/gif, image/webp, video/mp4"
        />
      </Button>

      <Typography variant="body1">
        .jpg .png .webp .gif .mp4 | max size: 2MB{" "}
      </Typography>

      {error && <Typography color="warning.main">{error}</Typography>}
    </Box>
  );
};

export default FileUpload;
