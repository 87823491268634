import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import axios from "axios";
import ColorTitle from "./ColorTitle";
import configUrl from "../config/configUrl";
import { Container } from "@mui/material";
import Link from '@mui/material/Link';

const BoxCount = () => {
  const [topCreators, setTopCreators] = useState([]);
  const [topUploaders, setTopUploaders] = useState([]);
  const [uploads, setUploads] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const creatorsResponse = await axios.get(`${configUrl.apiUrl}/api/topData`);
        // Parse the creators and uploaders data
        const parsedCreators = Object.values(creatorsResponse.data.authors).map((author) => JSON.parse(author));
        const parsedUploaders = Object.values(creatorsResponse.data.uploaders).map((uploader) => JSON.parse(uploader));
        const uploadsCount = parseInt(creatorsResponse.data.uploads, 10);

        setTopCreators(parsedCreators);
        setTopUploaders(parsedUploaders);
        setUploads(uploadsCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const getIconByPosition = (position) => {
    switch (position) {
      case 1:
        return <EmojiEventsIcon style={{ color: 'gold' }} />;
      case 2:
        return <EmojiEventsIcon style={{ color: 'silver' }} />;
      case 3:
        return <EmojiEventsIcon style={{ color: '#cd7f32' }} />; // Bronze
      case 4:
        return <Looks4Icon />;
      case 5:
        return <Looks5Icon />;
      default:
        return null;
    }
  };

  const renderTopUsers = (users) => {
    if (!Array.isArray(users)) {
      return null;
    }
    return users.map((user, index) => (
      <Box key={user.id} sx={{ display: "flex", width: "100%", flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "space-between" }}>
        {getIconByPosition(index + 1)}
        <Typography sx={{ fontFamily: 'TwitterChirp' }}>
          <Link href={user.url} target = "_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
            {user.name ? user.name : user.username}
          </Link></Typography>
          <img
          src={user.profile_image_url}
          alt={user.username}
          width="25"
          height="25"
          style={{ borderRadius: "50%" }}
        />
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 3,
        backgroundColor: "background.paper",
        width: "100%",
        borderRadius: 2,
        boxShadow: 3,
        textAlign: "center",
        "@media (min-width: 768px)": {
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 4,
          "@media (min-width: 768px)": {
            flexDirection: "row",
            alignItems: "flex-start",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" ,mb:0, pb:0}}>
            <ColorTitle
              fontS="1.5"
              text1="Uploaded"
              text2="Michi's"
              flexXS="row"
              CompH="h2"
              sx={{ mt: "20px" }}
            />
            <Tooltip title="Total number of michis uploaded." placement="top" enterDelay={0}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Box>
          <Typography component={"p"} fontSize={"5em"}>{uploads}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
            <ColorTitle
              fontS="1.5"
              text1="Top 5"
              text2="Creators"
              flexXS="row"
              CompH="h2"
              sx={{ mt: "20px" }}
            />
            <Tooltip title="Top creators based on uploads with claimed authorship" placement="top" enterDelay={0}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Box>
          {renderTopUsers(topCreators)}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
            <ColorTitle
              fontS="1.5"
              text1="Top 5"
              text2="Uploaders"
              flexXS="row"
              CompH="h2"
              sx={{ mt: "20px" }}
            />
            <Tooltip title="Top uploaders based on number of uploads" placement="top" enterDelay={0}>
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Box>
          {renderTopUsers(topUploaders)}
        </Box>
      </Box>
    </Box>
  );
};

export default BoxCount;
