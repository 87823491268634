import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import HeroClean from "../components/HeroClean";
import ImageGallery from "../components/ImageGallery";
import configUrl from "../config/configUrl";

export default function MichiCategory() {
  const { category } = useParams();
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCategoryResults = useCallback(async () => {
    try {
      console.log(`Fetching results for category: ${encodeURIComponent(category)}`);
      const response = await axios.get(
        `${configUrl.apiUrl}/api/michi-category/${encodeURIComponent(category)}`
      );
      setResults(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch results. Please try again later.");
      setLoading(false);
    }
  }, [category]);

  useEffect(() => {
    setLoading(true);
    fetchCategoryResults();
  }, [category, fetchCategoryResults]);

  return (
    <>
      <HeroClean
        title1={category}
        title2="Category Image"
        pageDescrip={`Here you can find ${category} images from michi meme.`}
      />
      <Box sx={{ bgcolor: "background.default", minHeight: "100vh", p: 3 }}>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && <ImageGallery images={results} updateImages={setResults} />}
      </Box>
    </>
  );
}
