import React, { useState } from "react";
import HeroClean from "../components/HeroClean";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from "@mui/lab";
import { Typography, Box, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { styled } from "@mui/material/styles";

const tasksDone = [
  { id: 1, label: "Launching Private Beta", date: "JUL/2024", completed: true },
  { id: 2, label: "Provide Data on Main Page", date: "JUL/2024", completed: true },
  { id: 3, label: "Create Ideas Page", date: "JUL/2024", completed: true },
  { id: 4, label: "Launchi Public Beta", date: "JUL/2024", completed: true },
  { id: 9, label: "Create User Page to view uploads and creations", date: "AUG/2024", completed: true },
  // ...mais tarefas concluídas
];

const tasksPending = [
  { id: 108, label: "View Liked Michis on User Page and Michigories", date: "AUG/2024", completed: false },
  { id: 109, label: "Create Michi Single Page", date: "AUG/2024", completed: false },
  { id: 110, label: "Claim Authorship Function for creators", date: "AUG/2024", completed: false },
  { id: 111, label: "Edit Uploads Descripton and Category on User Page", date: "AUG/2024", completed: false },
  { id: 112, label: "Donate to Creators Function", date: "SEPT/2024", completed: false },
  { id: 113, label: "Filter by Creators", date: "SEPT/2024", completed: false },
  { id: 114, label: "Creators Michi Awards", date: "OCT/2024", completed: false },

  // ...mais tarefas pendentes
];

const CustomTimelineDot = styled(TimelineDot)(({ theme, completed }) => ({
  backgroundColor: completed ? theme.palette.success.main : theme.palette.grey[400],
  color: completed ? theme.palette.common.white : theme.palette.grey[600],
}));

export default function RoadMap() {
  const [showAllDone, setShowAllDone] = useState(false);
  const [showAllPending, setShowAllPending] = useState(false);

  const toggleShowAllDone = () => setShowAllDone(!showAllDone);
  const toggleShowAllPending = () => setShowAllPending(!showAllPending);

  const renderTimelineItems = (tasks, showAll, reverse = false) => {
    const tasksToShow = showAll ? tasks : tasks.slice(0, 4);
    const items = tasksToShow.map((task) => (
      <TimelineItem key={task.id}>
        <TimelineOppositeContent color="text.secondary" sx={{alignItems: "center", alignContent: "center"}}>
          {task.date}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <CustomTimelineDot completed={task.completed}  sx={{alignItems: "center", alignContent: "center"}}>
            {task.completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
          </CustomTimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent  sx={{alignItems: "center", alignContent: "center"}}>
          <Typography variant="body1" color="primary.main">{task.label}</Typography>
        </TimelineContent>
      </TimelineItem>
    ));
    return reverse ? items.reverse() : items;
  };

  return (
    <Box>
      <HeroClean
        title1="Road"
        title2="map"
        pageDescrip="We're here to stay! By Michi Devs."
      />
      <Box sx={{ p: 4 }}>

        <Timeline position="alternate">
          {showAllDone && renderTimelineItems(tasksDone, true)}
          {!showAllDone && renderTimelineItems(tasksDone.slice(0, 4), false)}
        </Timeline>
        <Timeline position="alternate">
          {renderTimelineItems(tasksPending, showAllPending)}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
              <TimelineContent>
              <Button variant="contained" onClick={toggleShowAllPending} sx={{ mt: 2 }}>
                {showAllPending ? "Show Less" : "Show More"}
              </Button>
              </TimelineContent>
          </TimelineItem>
    </Timeline>
    </Box >
    </Box >
    
  );
}
