import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hero from "../components/Hero";
import FAQ from "../components/FAQ";
import HeroClean from "../components/HeroClean";
import configUrl from "../config/configUrl";

export default function FourZeroFour({ userStatus }) {
    return (
        <Box sx={{ bgcolor: "background.default" }}>
            <HeroClean title1={"You are"} title2={"Lost"} />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    mb:10,
                }}
            >
                <img
                    src={`${configUrl.apiUrl}/michi_404_400p.png`}
                    alt="logo michibook"
                    loading="lazy"
                />
            </Box>
            <Divider />
        </Box>
    );
}
