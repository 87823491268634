import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import configUrl from "../config/configUrl";

const SuggestionForm = ({ fetchSuggestions }) => {
  const [name, setName] = useState("");
  const [txs, setTxs] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // Novo estado para controlar a exibição da mensagem de sucesso

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!name || !txs || !suggestion) {
      setError("All fields are required.");
      return;
    }

    if (txs.length < 64 || txs.length > 128) {
      setError(
        "Your transaction signature (TXS) is not valid. Please, insert a valid TXS."
      );
      return;
    }

    if (suggestion.length > 300) {
      setError("Suggestion cannot exceed 300 characters.");
      return;
    }

    setError("");

    try {
      await axios.post(`${configUrl.apiUrl}/api/suggestions`, {
        name,
        txs,
        suggestion,
      });

      setName("");
      setTxs("");
      setSuggestion("");
      setSuccess(true); // Exibir mensagem de sucesso
    } catch (error) {
      console.error("Error submitting suggestion:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        alignItems: "center",
        mb: 8,
      }}
    >
      {success ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CheckCircleOutlineIcon
            color="success"
            sx={{ fontSize: 40, mb: 2 }}
          />
          <Typography color="success" sx={{ mb: 2 }}>
            Suggestion submitted successfully!
          </Typography>
        </Box>
      ) : (
        <>
          <TextField
            fullWidth
            label="Username"
            placeholder="Your name, X account, nickname, etc..."
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            sx={{ mb: 2 }}
            disabled={success} // Desabilitar o campo após o envio
          />
          <TextField
            fullWidth
            label="Donation TXS"
            placeholder="Place here the TX signature of your donation..."
            variant="outlined"
            value={txs}
            onChange={(e) => setTxs(e.target.value)}
            required
            sx={{ mb: 2 }}
            disabled={success} // Desabilitar o campo após o envio
          />
          <TextField
            fullWidth
            label="Suggestion"
            variant="outlined"
            multiline
            rows={4}
            value={suggestion}
            onChange={(e) => setSuggestion(e.target.value)}
            required
            inputProps={{ maxLength: 300 }}
            sx={{ mb: 2 }}
            disabled={success} // Desabilitar o campo após o envio
            InputProps={{
              endAdornment: (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    color: "text.secondary.light",
                  }}
                >
                  {suggestion.length}/300
                </Box>
              ),
            }}
          />
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={success}
          >
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};

export default SuggestionForm;
