import * as React from 'react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

export default function SitemarkIcon() {
  return (
    <Stack 
  >
    <Typography fontSize='2em' 
      color='primary.main'
      fontWeight='900'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      $michi
      <Typography fontSize='1em' 
      component="span"
      color='text.primary'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
        book
      </Typography>
    </Typography>
  </Stack>
  );
}
