import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import HeroClean from "../components/HeroClean";
import ImageGallery from "../components/ImageGallery";
import configUrl from "../config/configUrl";
import { Container, Typography } from "@mui/material";
import ColorTitle from "../components/ColorTitle";

export default function MichiImagePage() {
  const { image } = useParams();
  const [results, setResults] = useState([]);
  const [resultImage, setResultsImg] = useState();
  const [resultUser, setResultsUsr] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const imageLeg = image.split('-').length;

  const fetchCategoryResults = useCallback(async () => {
    try {
      const idMichi = image.split('-')[0];
      const response = await axios.get(
        `${configUrl.apiUrl}/api/michi-images/${idMichi}`
      );
      console.log("response.data", response.data);
      setResults(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch results. Please try again later.");
      setLoading(false);
    }
  }, [image]);

  useEffect(() => {
    setLoading(true);
    fetchCategoryResults();
  }, [fetchCategoryResults]);

  useEffect(() => {
    if (results.length > 0) {
      setResultsImg(results[0]);
      setResultsUsr(results[1]);
    }
  }, [results]);

  return (
    <>
      <HeroClean
        title1="Michi Image"
        title2={`${image.split('-')[0]}`}
        pageDescrip={`This page is about the michi with ID ${image.split('-')[0]}, which belongs to the categories: ${resultImage?.categories}. This michi was created by ${resultUser?.name}.`}
      />
      <Container sx={{alignContent: "center", alignItems: "center", textAlign: "center",}}>
        <ColorTitle text1={"Image"} text2={"data"} CompH="h3" fontS={2}/>
        <Typography sx={{mt:2}}>ID: {resultImage?.id}</Typography>
        <Typography>File Name: {resultImage?.file_name}</Typography>
        <Typography>Categories: {resultImage?.categories}</Typography>
        <Typography>Description: {resultImage?.description}</Typography>
        <Typography>Likes Count: {resultImage?.likes}</Typography>
        <Typography>Upload Date: {resultImage?.upload_date}</Typography>

        <Box sx={{ bgcolor: "background.default", minHeight: "100vh", p: 3 }}>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {resultImage && (
            <img
              src={`${configUrl.apiUrl}/uploads/${resultImage.file_name}`}
              alt={`A Michi meme from Solana - $michi is the memeble infinity meme - this specific michi is ${resultImage?.description} - Categories: ${resultImage?.categories} - Made by: ${resultUser?.name}`}
              loading="lazy"
              style={{
                width: "100%",
                aspectRatio: "1",
                borderRadius: 10,
                padding: "2px",
              }}
            />
          )}
        </Box>
      </Container>
    </>
  );
}
