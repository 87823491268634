import React, { useState, useContext } from "react";
import { Box, IconButton, TextField, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { AlertContext } from "../context/AlertContext";
import configUrl from "../config/configUrl";

const FloatingInput = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { showAlert } = useContext(AlertContext);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (inputValue === "") {
        return;
      }
      await axios.post(`${configUrl.apiUrl}/api/submit`, { inputValue });
      // Após o envio, você pode limpar o campo e fechar a caixa de texto, se desejar
      showAlert("Thankyou!", "success");
      setInputValue("");
      setIsOpen(false);
    } catch (error) {
      console.error("Error submitting input value:", error);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 30,
        left: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        }}
      >
        <AddIcon />
      </IconButton>
      {isOpen && (
        <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
          <TextField
            value={inputValue}
            onChange={handleChange}
            label="What can be improved? Any bug?"
            variant="filled"
            size="small"
            sx={{ mb: 1 }}
          />
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FloatingInput;
