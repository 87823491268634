// src/components/Author.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  ClickAwayListener,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Author = ({ author, setAuthor, user }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  const handleAuthorChange = (event) => {
    // console.log("handleAuthorChange author: ", author);
    const value = event.target.value;
    if (value === "") {
      setAuthor("");
    } else {
      setAuthor(value);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      disableFocusListener
      open={open}
      onClose={handleTooltipClose}
      placement="top"
      TransitionProps={{ timeout: 600 }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Typography variant="body" gutterBottom>
          Who made this Michi?
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit"><b>{"Only logged in"}</b>{" users can claim authorship!"}</Typography>
                <b><u><a href="https://michibook.cloud/x/login">{"Click here"}</a></u></b> <em>{"to LogIn"}</em>
              </React.Fragment>
            }
          >
            <Button
              onClick={handleTooltipOpen}
              onMouseEnter={handleTooltipOpen}
            >
              <InfoIcon />
            </Button>
          </HtmlTooltip>
        </Typography>
        <RadioGroup
          sx={{
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
          row
          onChange={handleAuthorChange}
        >
          <FormControlLabel
            disabled={isAuthenticated ? false : true}
            value={isAuthenticated ? user.userId : ""}
            control={<Radio />}
            label={isAuthenticated ? user.username : "I made it"}
          />
          <FormControlLabel value="unknown" control={<Radio />} label="Not me" />
        </RadioGroup>
      </Box>
    </ClickAwayListener>
  );
};

export default Author;
