import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import configUrl from "../config/configUrl";
import ColorTitle from "./ColorTitle";

const RequestForm = ({ userData }) => {
  const [url, setUrl] = useState("");
  const [idea, setIdea] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // Novo estado para controlar a exibição da mensagem de sucesso

  const handleLogin = () => {
    window.location.href = `${configUrl.apiUrl}/x/login`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!idea) {
      setError("The field 'Idea' is required.");
      return;
    }

    if (idea.length > 100) {
      setError("Idea cannot exceed 100 characters.");
      return;
    }

    setError("");
    const userId = userData.userId;
    try {
      const succId = await axios.post(`${configUrl.apiUrl}/api/requests`, {
        url,
        idea,
        userId,
      });
      console.log("succId: ", succId);

      if (succId.data.success === true){
        setUrl("");
        setIdea("");
        setSuccess(true); // Exibir mensagem de sucesso
      }
    } catch (error) {
      console.error("Error submitting idea:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mb: 8,
        gap: 2,
        p: 2,
        mt: 5,
        bgcolor: "background.paper",
        borderRadius: 5,
        boxShadow: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {userData ?
        (<>
          {success ?
            (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ fontSize: 40, mb: 2 }}
                />
                <Typography color="success" sx={{ mb: 2 }}>
                  Idea submitted successfully!
                </Typography>
              </Box>
            ) : (
              <Box sx={{
                display: "flex",
                flexFlow: "column wrap",
                justifyContent: "center",
                alignItems: "center",
                width: '100%',
                height: '100%',
              }}><Typography component={"p"} sx={{mb:2, textAlign:"center"}}>Gmichi <Typography color="primary.main" component={"spam"}>@{userData.username}</Typography>,<br/> what's your idea for the next michi?</Typography>
                <TextField
                  fullWidth
                  label="Idea"
                  variant="outlined"
                  placeholder="A michi climbing the Everest would be nice..."
                  multiline
                  rows={2}
                  value={idea}
                  onChange={(e) => setIdea(e.target.value)}
                  required
                  inputProps={{ maxLength: 100 }}
                  sx={{ mb: 2 }}
                  disabled={success} // Desabilitar o campo após o envio
                  InputProps={{
                    endAdornment: (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          right: 16,
                          color: "text.secondary.light",
                        }}
                      >
                        {idea.length}/100
                      </Box>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="URL (optional)"
                  placeholder="Image reference, X account, etc..."
                  variant="outlined"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  sx={{ mb: 2 }}
                  disabled={success} // Desabilitar o campo após o envio
                />
                {error && (
                  <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                  </Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={success}
                >
                  Submit
                </Button>
              </Box>
            )
          }
        </>
        ) : (
          <Box sx={{ position: 'relative', display: 'inline-block', minHeight: "200px", minWidth: "100%" }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: "background.paper",
                zIndex: 1,
              }}
            >
              <ColorTitle text1={"Log in to"} text2={" Submit"} CompH="h3" fontS={1.5}/>
              <Typography sx={{ mb: 2, mt:2 , textAlign:"center" }}>To post an idea, you need to be logged in. Click the button below to log in with X.</Typography>
              <Button variant="contained" color="primary" onClick={handleLogin}>

                Login with&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </Button>
            </Box>
          </Box>

        )}
    </Box>
  );
};

export default RequestForm;
