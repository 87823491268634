import React, { useState } from "react";
import { Box, TextField, Button, Autocomplete } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import configUrl from "../config/configUrl";

const SearchForm = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  // console.log("ON SEARCH: ",onSearch)

  const handleSearch = () => {
    if (onSearch) {
      onSearch(query);
    } else {
      navigate(`/find-michi?query=${query}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const fetchAutocompleteOptions = async (inputValue) => {
    try {
      const response = await axios.get(
        `${configUrl.apiUrl}/api/autocomplete?query=${inputValue}`
      );
      setOptions(response.data);
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setQuery(newInputValue);
    if (newInputValue.length > 2) {
      fetchAutocompleteOptions(newInputValue);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: { xs: "column", sm: "row" },
        gap: 2,
        p: 2,
        mt: 5,
        bgcolor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Autocomplete
        freeSolo
        options={options}
        inputValue={query}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Michi"
            variant="outlined"
            onKeyDown={handleKeyDown}
            sx={{ width: { xs: "300px", sm: "300px" } }}
          />
        )}
      />
      <Button variant="contained" color="primary" onClick={handleSearch}>
        Search
      </Button>
    </Box>
  );
};

export default SearchForm;
