import Box from "@mui/material/Box";
import axios from "axios";
import HeroClean from "../components/HeroClean";
import ImageGallery from "../components/ImageGallery";
import configUrl from "../config/configUrl";
import React, { useState, useEffect} from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

export default function Michigories( {catList, userStatus} ) {

  const [cat, setCat] = useState("");
  const [results, setResults] = useState([]);

  const handleCat = (event, newCat) => {
    if (newCat !== null) {
      setCat(newCat);
      handleCatImg(newCat);
    }
  };
  const categories = catList;
  const sortedCategoryList = categories.sort((a, b) => a.localeCompare(b));

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .MuiToggleButton-root`]: {
      margin: theme.spacing(0.5),
      border: 10,
      borderRadius: 50,
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  }));

  const handleCatImg = async (newCat) => {
    try {
      const url = `${configUrl.apiUrl}/api/search?categories=${newCat}`;
      const response = await axios.get(url);
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  
  return (
    <Box>
      <HeroClean
        title1={"Michi's"}
        title2="by Category"
        pageDescrip="Choose a michigorie. Here you can find any michi"
      />
      <Container sx={{ bgcolor: "background.default", p: 3 }}>
        <StyledToggleButtonGroup
          value={cat}
          exclusive
          onChange={handleCat}
          aria-label="category selection"
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          {sortedCategoryList.map((category) => (
            <ToggleButton value={category}>{category}</ToggleButton>
          ))}
          {userStatus ? <ToggleButton value={"All Categories"}>All Categories</ToggleButton> : <></>}
        </StyledToggleButtonGroup>
        <ImageGallery images={results} updateImages={setResults} />
      </Container>
    </Box>
  );
}
