import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import FileUpload from "./FileUpload";
import CategorySelector from "./CategorySelector";
import DescriptionField from "./DescriptionField";
import ColorTitle from "./ColorTitle";
import configUrl from "../config/configUrl";
import { Link } from "@mui/material";
import Author from "./Author";
import { AlertContext } from '../context/AlertContext';

const UploadBox = ({userStatus}) => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [errorico, setErrorIco] = useState(false);
  const [errorLink, setErrorLink] = useState("");
  const [authorID, setAuthor] = useState("");
  const { showAlert } = useContext(AlertContext);

  const handleFileChange = (selectedFile) => {
    const validTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/webp",
      "video/mp4",
      "video/webm",
      "video/ogg",
    ];
    if (!validTypes.includes(selectedFile.type)) {
      setErrorIco(true);
      setError(
        "Invalid file type. Only PNG, JPEG, WEBP, MP4, WEBM, OGG, and GIF are allowed."
      );
      setErrorLink("");
      return;
    }
    if (selectedFile.size > 10 * 1024 * 1024) {
      setErrorIco(true);
      setError("File size exceeds 2MB.");
      setErrorLink("");
      return;
    }
    setFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
    setError("");
    setErrorLink("");
  };

  const handleCategoryChange = (newCategories) =>
    setSelectedCategories(newCategories);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleUpload = async () => {
    if (!file || selectedCategories.length === 0 || !description || !authorID) {
      setSuccess(false);
      setErrorIco(true);
      setError("*All fields are required.*");
      setErrorLink("");
      return;
    }

    if (description.length < 5) {
      setSuccess(false);
      setErrorIco(true);
      setError("*Description must be at least 5 characters.*");
      setErrorLink("");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("categories", JSON.stringify(selectedCategories));
    formData.append("description", description);
    formData.append("authorID", authorID);
    setSuccess(false);
    setErrorIco(false);
    setError("");
    setErrorLink("");
    setLoading(true);
    try {
      const response = await axios.post(
        `${configUrl.apiUrl}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showAlert("Michi sent!", "success");
      if (response.data.success) {
        setLoading(false);
        setErrorIco(false);
        setSuccess(true);
        setFile(null);
        setFilePreview(null);
        setSelectedCategories([]);
        setDescription("");
        setError("");
        setErrorLink("");
      } else {
        setLoading(false);
        setErrorIco(true);
        setError(response.data.message || "*File upload failed.*");
        setErrorLink(response.data.link); // Usar link do servidor
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
      setErrorIco(true);
      setError(error.response?.data?.message || "*Error uploading file.*");
      setErrorLink(error.response?.data?.link); // Usar link do servidor
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        p: 2,
        backgroundColor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
        textAlign: "center",
        "@media (min-width: 768px)": {
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <ColorTitle
        fontS="2.5"
        text1="upload"
        text2="box"
        flexXS="row"
        sx={{ mt: "20px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "space-evenly",
          gap: 2,
          "@media (min-width: 768px)": {
            flexDirection: "row",
            alignItems: "flex-start",
          },
        }}
      >
        <FileUpload
          onFileChange={handleFileChange}
          filePreview={filePreview}
          error={error}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            alignContent: "center",
            width: { xs: "100%", sm: "50%" }, // Ajustar largura no desktop e mobile
            height: "100%",
          }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CategorySelector
            categories={selectedCategories}
            onCategoryChange={handleCategoryChange}
          />
          <DescriptionField
            description={description}
            onDescriptionChange={handleDescriptionChange}
          />

        </Box>
      </Box>
      <Author author={authorID} setAuthor={setAuthor} user={userStatus} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={loading}
            endIcon={<SendIcon />}
            sx={{ mt: 1, alignSelf: "center", width: "fit-content" }} // Ajustar largura e margem do botão
          >
            Upload
          </Button>
      <Box>
        {error && (
          <Typography color="info">
            {error} {errorLink && <Link href={errorLink}>See here!</Link>}
          </Typography>
        )}
        {loading && <CircularProgress />}
        {errorico && <ErrorOutlineIcon color="info" sx={{ fontSize: 40 }} />}
        {success && (
          <Box>
            <Typography color="success.main">
              Your michi has been successfully sent to the moon. 🐱‍🚀🌛
            </Typography>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40 }} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UploadBox;
