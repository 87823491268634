// src/pages/SuggestionsPage.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import axios from "axios";
import SuggestionForm from "../components/SuggestionForm";
import SuggestionList from "../components/SuggestionList";
import HeroClean from "../components/HeroClean";
import CopyText from "../components/CopyText";
import ColorTitle from "../components/ColorTitle";
import configUrl from "../config/configUrl";

const SuggestionsPage = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [recentSuggestions, setRecentSuggestions] = useState([]);
  const [view, setView] = useState("popular");
  const [limit, setLimit] = useState(10); // Inicialmente exibindo 10 sugestões


  const fetchSuggestions = async () => {
    try {
      const response = await axios.get(`${configUrl.apiUrl}/api/suggestions`);
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const fetchRecentSuggestions = async () => {
    try {
      const response = await axios.get(`${configUrl.apiUrl}/api/recent-suggestions`);
      setRecentSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching recent suggestions:", error);
    }
  };

  useEffect(() => {
    fetchSuggestions();
    fetchRecentSuggestions();
  }, []);

  const handleVote = () => {
    fetchSuggestions();
    fetchRecentSuggestions();
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const showMoreSuggestions = () => {
    setLimit((prevLimit) => prevLimit + 10); // Aumenta o limite em 10
  };

  return (
    <Box>
      <HeroClean
        title1="Submit a"
        title2="Suggestion"
        pageDescrip={
          <>
            We greatly value your input and suggestions for the Michi Book
            Project.
            <br />
            To submit a suggestion, we kindly request a{" "}
            <Typography
              component="span"
              sx={{
                color: "primary.main",
              }}
            >
              donation of ANY amount of SOL
            </Typography>{" "}
            to support our ongoing efforts.
          </>
        }
      />
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <CopyText text={"6gve5AoHFgYru1Ko37EQ1iNi5gfULZEWqHUd7TF3FBGU"} />
      </Box>
      <Container
        sx={{
          display: "box",
          flexFlow: "column",
          justifyContent: "center",
          width: { xs: "100%", md: "50%" },
          alignItems: "center",
          mt: 4,
        }}
      >
        <SuggestionForm onNewSuggestion={fetchSuggestions} />
      </Container>
      <Container
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          width: { xs: "100%", md: "80%" },
          alignItems: "center",
          mt: 4,
        }}
      >
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="suggestion view"
          sx={{ mb: 4 }}
        >
          <ToggleButton value="popular" aria-label="most popular">
            Most Popular
          </ToggleButton>
          <ToggleButton value="recent" aria-label="most recent">
            Most Recent
          </ToggleButton>
        </ToggleButtonGroup>

        {view === "popular" && (
          <>
            <ColorTitle
              text1={"Most Popular"}
              text2={"Suggestions"}
              fontS={1.8}
              CompH="h2"
            />
            <SuggestionList
              suggestions={suggestions.slice(0, limit)}
              onVote={handleVote}
              fetchSuggestions={fetchSuggestions}
              
            />
          </>
        )}
        {view === "recent" && (
          <>
            <ColorTitle
              text1={"Most Recent"}
              text2={"Suggestions"}
              fontS={1.8}
              CompH="h2"
            />
            <SuggestionList
              suggestions={recentSuggestions.slice(0, limit)}
              onVote={handleVote}
              fetchSuggestions={fetchSuggestions}
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={showMoreSuggestions}
          sx={{ mt: 2 }}
        >
          Show More
        </Button>
        <Divider sx={{ my: 4 }} />
      </Container>
      </Box>
  );
};

export default SuggestionsPage;
