import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

const CopyText = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const [hover, setHover] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 4000); // Mensagem desaparece após 3 segundos
    }, (err) => {
      console.error('Error copying text: ', err);
    });
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const StyledTypography = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    color: hover ? theme.palette.primary.main : 'inherit',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    cursor: 'pointer',
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 0,
        p: 1,
        backgroundColor: 'background.paper',
        borderRadius: 1,
        boxShadow: 1,
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onClick={copyToClipboard}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0,
          width: '100%',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={copyToClipboard}
      >
        <StyledTypography variant="body2" sx={{fontSize:{xs:'0.8em', sm:'default'}}}>
          {text}
        </StyledTypography>
        <Tooltip title="Copy">
          <IconButton onClick={(e) => { e.stopPropagation(); copyToClipboard(); }}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={copied}>
        <Typography variant="caption" color="success.main" sx={{ mt: 2 }}>
          Solana Wallet copied to clipboard!
        </Typography>
      </Collapse>
    </Box>
  );
};

export default CopyText;
