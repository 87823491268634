import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ColorTitle = ({
  text1,
  text2,
  fontS,
  CompH = "h2",
}) => {
  return (
    <Box
      spacing={20}
      sx={{alignItems: "center" }}
    >
      <Typography
        fontSize={`${fontS}em`}
        variant="h2"
        component={CompH}
        fontWeight="700"
        sx={{
          alignItems: "center",
          textAlign: 'center',
        }}
      >
        {text1}
        <Typography
          fontSize="1em"
          fontWeight="800"
          component="span"
          variant="h2"
          sx={(theme) => ({
            fontSize: "inherit",
            textAlign: "center",
            color: "primary.main",
            ...theme.applyStyles("dark", {
              color: "primary.main",
            }),
          })}
        >
          &nbsp;{text2}
        </Typography>
      </Typography>
    </Box>
  );
};

export default ColorTitle;
