import React, { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import HeroClean from "../components/HeroClean";
import MichiList from "../components/MichiList";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DrawIcon from '@mui/icons-material/Draw';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: open ? '100%' : theme.spacing(7),
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            boxSizing: 'border-box',
            overflowX: open ? 'auto' : 'hidden',
        },
    }),
);

const UserPage = ({ userStatus }) => {
    const StyledBox2 = styled("div")(({ theme }) => ({
        alignSelf: "center",
        outline: "1px solid",
        outlineColor: "hsla(220, 25%, 80%, 0.5)",
        backgroundSize: "cover",
        ...theme.applyStyles("dark", {
            outlineColor: "hsla(26, 100%, 80%, 0.1)",
        }),
    }));

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState("uploads");

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleContent = (newContent) => {
        if (content !== newContent) {
            setContent(newContent);
        }
    };

    const contentComponent = useMemo(() => {
        switch (content) {
            case 'uploads':
                return <MichiList author={"all"} />;
            case 'creations':
                return <MichiList author={userStatus} />;
            case 'favorites':
                return <MichiList author={"master"} />;
            case 'data':
                return <div>data</div>;
            default:
                return null;
        }
    }, [content, userStatus]);
    console.log("userstatus",userStatus)

    return (
        <Box sx={{ bgcolor: "background.default" }}>
            <HeroClean
                title1="User"
                title2="Page"
                pageDescrip={`gmichi ${userStatus.username}.`}
            />
            <Container sx={{ display: "flex", width: "100%", justifyContent: "center", flexGrow: 1 }}>
                {userStatus ?
                    <StyledBox2>
                        <Box sx={{ display: 'flex', width: "100%", alignContent: "center", flexGrow: 1 }} >
                            <Drawer variant="permanent" open={open} sx={{ zIndex: 0 }}>
                                <Toolbar
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        px: [1],
                                    }}
                                >
                                    <IconButton onClick={toggleDrawer}>
                                        <MenuIcon />
                                    </IconButton>
                                </Toolbar>
                                <Divider />
                                <List component="nav">
                                    <ListItemButton onClick={() => handleContent("uploads")}>
                                        <ListItemIcon>
                                            <CloudUploadIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Uploads" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleContent("creations")}>
                                        <ListItemIcon>
                                            <DrawIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Creations" />
                                    </ListItemButton>
                                </List>
                                {userStatus.userId === 2 ?
                                <List component="nav">
                                    <ListItemButton onClick={() => handleContent("favorites")}>
                                        <ListItemIcon>
                                        <DrawIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Favorite" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleContent("data")}>
                                        <ListItemIcon>
                                        <DrawIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="My Data" />
                                    </ListItemButton>
                                    </List>
                            : <></>}
                        </Drawer>
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                height: 'auto',
                                overflow: 'auto',
                                width: "100%",
                            }}
                        >
                            <Toolbar />
                            <Container maxWidth="lg">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', width: "100%", flexGrow: 1, minWidth: "100%" }}>
                                            {contentComponent}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                    </StyledBox2>
            : <></>}
        </Container>
        </Box >
    );
}

export default React.memo(UserPage);
