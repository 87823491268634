import * as React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import ToggleColorMode from "./ToggleColorMode";
import Sitemark from "./SitemarkIcon";
import configUrl from "../config/configUrl";
import { Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function AppAppBar({ mode, toggleColorMode, userStatus }) {
  const [open, setOpen] = React.useState(false);
  // const [theme, setTheme] = React.useState(toggleColorMode);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Adicione a lógica de logout aqui
    window.location.href = `${configUrl.apiUrl}/x/logout`;
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl]);

  const handleLogin = () => {
    window.location.href = `${configUrl.apiUrl}/x/login`;
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "20px",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            bgcolor: "hsla(26, 53%, 99%, 0.6)",
            boxShadow:
              "0 1px 2px hsla(26, 0%, 0%, 0.05), 0 2px 12px hsla(26, 53%, 80%, 0.5)",
            ...theme.applyStyles("dark", {
              bgcolor: "hsla(220, 0%, 0%, 0.7)",
              boxShadow:
                "0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(26, 53%, 25%, 0.3)",
            }),
          })}
        >
          <Box>
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <Sitemark />
            </RouterLink>
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <MenuItem
              sx={{ color: "primary.main" }}
              component={RouterLink}
              to="/"
            >
              Home
            </MenuItem>
            <MenuItem
              sx={{ color: "primary.main" }}
              component={RouterLink}
              to="/find-michi"
            >
              Search
            </MenuItem>
            <MenuItem
              sx={{ color: "primary.main" }}
              component={RouterLink}
              to="/requests"
            >
              Ideas
            </MenuItem>
            <MenuItem
              sx={{ color: "primary.main" }}
              component={RouterLink}
              to="/michigories"
            >
              Michigories
            </MenuItem>
            <MenuItem
              sx={{ color: "primary.main" }}
              component={RouterLink}
              to="/suggestions"
            >
              Suggestions
            </MenuItem>
            <MenuItem
              sx={{ color: "primary.main" }}
              component={RouterLink}
              to="/roadmap"
            >
              Roadmap
            </MenuItem>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            {userStatus ? (
              <Box
                onClick={handleMenu}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  paddingLeft: 2,
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <Typography component="h2" color={"primary.main"} >{userStatus.username}</Typography>
                <img
                  src={userStatus.profile_image_url}
                  alt={userStatus.username}
                  width="30"
                  height="30"
                  style={{ borderRadius: "50%" }}
                />
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      component={RouterLink}
                      to="/user-page"
                    >
                      <AccountCircleIcon sx={{ mr: 1 }} /> My Account
                    </Button>
                  </MenuItem>
                  <Divider/>
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon sx={{ mr: 1 }} /> Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={handleLogin}
              >
                Sign In with&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-twitter-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </Button>
            )}
          </Box>

          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: "60dvw",
                  p: 2,
                  backgroundColor: "background.paper",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <ToggleColorMode
                    mode={mode}
                    toggleColorMode={toggleColorMode}
                  />
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem component={RouterLink} to="/">
                  Home
                </MenuItem>
                <MenuItem component={RouterLink} to="/find-michi">
                  Search
                </MenuItem>
                <MenuItem component={RouterLink} to="/requests">
                  Ideas
                </MenuItem>
                <MenuItem component={RouterLink} to="/michigories">
                  Michigories
                </MenuItem>
                <MenuItem component={RouterLink} to="/suggestions">
                  Suggestions
                </MenuItem>
                <MenuItem component={RouterLink} to="/suggestions">
                  Roadmap
                </MenuItem>
                <Divider sx={{ my: 3 }} />
                {userStatus ? (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        p: 2,
                      }}
                    >
                      <img
                        src={userStatus.profile_image_url}
                        alt={userStatus.username}
                        width="25"
                        height="25"
                        style={{ borderRadius: "50%" }}
                      />
                      <Typography component="h2" color={"primary.main"} paddingLeft={2}>{userStatus.username}</Typography>
                    </Box>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        component={RouterLink}
                        to="/user-page"
                      >
                        <AccountCircleIcon sx={{ mr: 1 }} /> My Account
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={handleLogout}
                      >
                        <LogoutIcon sx={{ mr: 1 }} /> Logout
                      </Button>
                    </MenuItem>
                  </Box>
                ) : (
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      fullWidth
                      onClick={handleLogin}
                    >
                      Sign In with&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </Button>
                  </MenuItem>
                )}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
