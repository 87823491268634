// components/Layout.js
import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "../components/AppAppBar"; // Atualize o caminho se necessário
import Footer2 from "../components/Footer2"; // Atualize o caminho se necessário
import getLPTheme from "../getLPTheme"; // Atualize o caminho se necessário
import FloatingInput from "../components/FloatingInput ";

const LayoutBase = ({catList, children, userStatus }) => {
  const [mode, setMode] = useState("dark");
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} userStatus={userStatus} />
      <div style={{ minHeight: "calc(100vh - 100px)" }}>
        {/* Adiciona um preenchimento mínimo para garantir que o conteúdo principal ocupe o espaço restante */}
        {children}
      </div>
      <Footer2 catList={catList} />
      <FloatingInput/>
    </ThemeProvider>
  );
};

export default LayoutBase;
