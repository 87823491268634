import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import FindMichi from "./pages/FindMichi";
import MichiCategory from "./pages/MichiCategory";
import Michigories from "./pages/Michigories";
import ScrollToTop from "./components/ScrollToTop";
import SuggestionsPage from "./pages/SuggestionPage";
import PrivateRoute from "./config/PrivateRoute";
import { AlertProvider } from "./context/AlertContext"; // Import AlertProvider
import AlertBar from "./components/AlertBar";
import LayoutBase from "./pages/LayoutBase";
import configUrl from "./config/configUrl";
import axios from "axios";
import RoadMap from "./pages/RoadMap";
import Requests from "./pages/Requests";
import UserPage from "./pages/UserPage";
import FourZeroFour from "./pages/FourZeroFour";
import MichiImagePage from "./pages/MichiImagePage"; 
// <Route path="/suggestions" element={<PrivateRoute element={SuggestionsPage} />} />

function App() {
  const [categories, setCategories] = React.useState([]);
  const [user, setUser] = React.useState(null);
  // const [user, setUser] = React.useState({
  //   username: "xdmt_btc",
  //   userId: 2,
  //   id: "1586933783747563520",
  //   profile_image_url:
  //     "https://pbs.twimg.com/profile_images/1786068054499119109/oBNo7HMY_normal.jpg",
  //   name: "xdmt.btc 🦡🟧▪️▫️",
  //   location: "memeverse",
  //   url: "https://x.com/xdmt_btc",
  // });
  //login
  React.useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch("/api/user");
        const data = await response.json();
        if (data.user) {
          setUser(data.user);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };
    checkLoginStatus();
  }, []);
  //categories
  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${configUrl.apiUrl}/api/category-counts`
        );
        // console.log("app usou /-api/category-counts");
        const categoryList = Object.keys(response.data);
        setCategories(categoryList);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  return (
    <AlertProvider>
      <AlertBar />
      <ScrollToTop />
      <LayoutBase userStatus={user} catList={categories}>
        <Routes>
          <Route path="/" element={<LandingPage userStatus={user} />} />
          <Route path="/user-page" element={<UserPage userStatus={user} />} />
          <Route path="/find-michi" element={<FindMichi userStatus={user} />} />
          <Route path="/suggestions" element={<SuggestionsPage userStatus={user} />} />
          <Route path="/requests" element={<Requests userStatus={user} />} />
          <Route path="/roadmap" element={<RoadMap userStatus={user} />} />
          <Route path="/michigories" element={<Michigories userStatus={user} catList={categories} />} />
          <Route path="/category/:category" element={<MichiCategory userStatus={user} />} />
          <Route path="*" element={<FourZeroFour userStatus={user} />} />
          <Route path="/images/:image" element={<MichiImagePage />} />
        </Routes>
      </LayoutBase>
    </AlertProvider>
  );
}

export default App;
