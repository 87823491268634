import React, { useState, useEffect, useContext } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { IconButton, Typography, Tooltip, Modal, Box } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FlagIcon from "@mui/icons-material/Flag";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { styled } from "@mui/material/styles";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import { AlertContext } from "../context/AlertContext";
import configUrl from "../config/configUrl";

const Overlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: "100%",
  width: "100%",
  opacity: 0,
  borderRadius: 10,
  transition: ".3s ease",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: theme.spacing(1),
  "&:hover": {
    opacity: 1,
  },
}));

const LikeOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "white",
}));

const  ImageGallery = ({ images, updateImages }) => {
  const [likedImages, setLikedImages] = useState([]);
  const [zoomedMedia, setZoomedMedia] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { showAlert } = useContext(AlertContext);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleDownload = async (fileName, id) => {
    const link = document.createElement("a");
    link.href = `${configUrl.apiUrl}/uploads/${fileName}`;
    link.target = "_blank";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    try {
      await axios.post(`${configUrl.apiUrl}/api/increment-download`, {id});
    } catch (error) {
      console.error("Error incrementing download count:", error);
    }
  };

  // const handleLink = (fileName) => {
  //   const linkUrl = `${configUrl.apiUrl}/api/image/${fileName}`;
  //   navigator.clipboard.writeText(linkUrl)
  //     .then(() => {
  //       console.log('Link copiado para a área de transferência');
  //     })
  //     .catch(err => {
  //       console.error('Erro ao copiar o link: ', err);
  //     });
  // };
  

  const handleZoom = (fileName) => {
    const media = images.find((image) => image.file_name === fileName);
    setZoomedMedia(media);
  };

  const handleCloseZoom = () => {
    setZoomedMedia(null);
  };

  const handleReport = async (imgID) => {
    try {
      await axios.post(`${configUrl.apiUrl}/api/report`, { imgID });
      showAlert("Report Sent! We will verify it!", "success");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showAlert("You need to be logged in to report an image.", "error");
      } else {
        console.error("Error reporting image:", error);
      }
    }
  };

  const isVideo = (fileName) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    return videoExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  };

  const handleLike = async (imageId) => {
    try {
      const isLiked = likedImages.includes(imageId);
      console.log("isLiked: ", isLiked);
      if (isLiked) {
        await axios.post(`${configUrl.apiUrl}/api/decrement-like`, {
          michiId: imageId,
        });
        setLikedImages((prev) => {
          const updatedLikes = prev.filter((id) => id !== imageId);
          return updatedLikes;
        });
        console.log("Chegou out");
        const updatedMedia = images.map((image) => {
          if (image.id === imageId) {
            console.log("image.likes before = ", image.likes);
            const updatedImage = { ...image, likes: image.likes - 1 };
            console.log("image.likes after = ", updatedImage.likes);
            return updatedImage;
          }
          return image;
        });
        updateImages(updatedMedia);
      } else {
        const response = await axios.post(`${configUrl.apiUrl}/api/increment-like`, {
          michiId: imageId,
        });
        const updatedLikes = response.data.likes; // Adicionado: Supondo que o backend retorna o número atualizado de likes
        console.log("updatedLikes", updatedLikes)

        setLikedImages((prev) => {
          const updLikes = [...prev, imageId];
          return updLikes;
        });
        const updatedMedia = images.map((image) => {
          if (image.id === imageId) {
            console.log("image.likes U before = ", image.likes);
            const updatedImage = { ...image, likes: updatedLikes };
            console.log("image.likes U after = ", updatedImage.likes);
            return updatedImage;
          }
          return image;
        });
        updateImages(updatedMedia);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showAlert("You need to be logged in to like an image.", "error");
      } else {
        console.error("Error toggling like count:", error);
      }
    }
  };
  useEffect(() => {
    const fetchLikedImages = async () => {
      try {
        const likedImages = await Promise.all(
          images.map(async (image) => {
            const response = await axios.get(
              `${configUrl.apiUrl}/api/check-like`,
              { params: { michiId: image.id } }
            );
            return response.data ? image.id : false;
          })
        );
        setLikedImages(likedImages.filter((id) => id !== false));
      } catch (error) {
        console.error("Error fetching liked images:", error);
      }
    };
    fetchLikedImages();
  }, [images]);

  return (
    <>
      <ImageList cols={isMobile ? 2 : 4} sx={{ margin: "20px" }}>
        {images.map((image) => (
          <ImageListItem
            key={image.file_name}
            style={{ position: "relative" }}
            onContextMenu={handleContextMenu}
          >
            {isVideo(image.file_name) ? (
              <video
                src={`${configUrl.apiUrl}/uploads/${image.file_name}`}
                alt={`A Michi meme from Solana - $michi is the memeble infinity meme - this especific michi is ${image.description} - Categories: ${image.categories} - Made by: ${image.author}`}
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "1",
                  borderRadius: 10,
                  padding: "2px",
                }}
                controls
              />
            ) : (
              <img
                src={`${configUrl.apiUrl}/uploads/${image.file_name}`}
                alt={`A Michi meme from Solana - $michi is the memeble infinity meme - this especific michi is ${image.description} - Categories: ${image.categories} - Made by: ${image.author}`}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "1",
                  borderRadius: 10,
                  padding: "2px",
                }}
              />
            )}
            <Overlay>
              <Tooltip title="Like">
                <LikeOverlay>
                  <IconButton
                    size="small"
                    style={{ color: "white" }}
                    onClick={() => handleLike(image.id)}
                  >
                    {likedImages.includes(image.id) ? (
                      <FavoriteIcon color="#FF0000" />
                    ) : (
                      <FavoriteBorderOutlinedIcon />
                    )}
                  </IconButton>
                  <Typography
                    variant="body2"
                    style={{ color: "white", marginLeft: 4 }}
                  >
                    {image.likes}
                  </Typography>
                </LikeOverlay>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton
                  size="small"
                  style={{ color: "white" }}
                  onClick={() => handleDownload(image.file_name,  image.id)}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Zoom">
                <IconButton
                  size="small"
                  style={{
                    position: "absolute",
                    top: 3,
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                  onClick={() => handleZoom(image.file_name)}
                >
                  <CropFreeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Report">
                <IconButton
                  size="small"
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 2,
                    color: "white",
                  }}
                  onClick={() => handleReport(image.id)}
                >
                  <FlagIcon />
                </IconButton>
              </Tooltip>
            </Overlay>
          </ImageListItem>
        ))}
      </ImageList>
      {zoomedMedia && (
        <Modal open={Boolean(zoomedMedia)} onClose={handleCloseZoom}>
          <Box
            onContextMenu={handleContextMenu}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "80%", md: "50%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            {isVideo(zoomedMedia.file_name) ? (
              <video
                src={`${configUrl.apiUrl}/uploads/${zoomedMedia.file_name}`}
                alt={`A Michi meme from Solana - $michi is the memeble infinity meme - this especific michi is ${zoomedMedia.description} - Categories: ${zoomedMedia.categories} - Made by: ${zoomedMedia.author}`}
                controls
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: 10,
                }}
              />
            ) : (
              <img
                src={`${configUrl.apiUrl}/uploads/${zoomedMedia.file_name}`}
                alt={`A Michi meme from Solana - $michi is the memeble infinity meme - this especific michi is ${zoomedMedia.description} - Categories: ${zoomedMedia.categories} - Made by: ${zoomedMedia.author}`}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: 10,
                }}
              />
            )}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: { xs: "space-between", md: "space-evenly" },
                width: "100%",
              }}
            >
              <Tooltip title="Close">
                <IconButton
                  size="small"
                  style={{ color: "text.primary" }}
                  onClick={handleCloseZoom}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton
                  size="small"
                  style={{ color: "text.primary" }}
                  onClick={() => handleDownload(zoomedMedia.file_name, zoomedMedia.id)}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Report">
                <IconButton
                  size="small"
                  style={{ color: "text.primary" }}
                  onClick={() => handleReport(zoomedMedia.id)}
                >
                  <FlagIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Like">
                <LikeOverlay>
                  <IconButton
                    size="small"
                    style={{ color: "text.primary" }}
                    onClick={() => handleLike(zoomedMedia.id)}
                  >
                    {likedImages.includes(zoomedMedia.id) ? (
                      <FavoriteIcon  color="#FF0000" />
                    ) : (
                      <FavoriteBorderOutlinedIcon />
                    )}
                  </IconButton>
                </LikeOverlay>
              </Tooltip>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ImageGallery;
