import React, { useContext } from 'react';
import { AlertContext } from '../context/AlertContext';
import { Snackbar, Alert } from '@mui/material';

const AlertBar = () => {
  const { alertMessage, isAlertOpen, alertType } = useContext(AlertContext);

  return (
    <Snackbar open={isAlertOpen} autoHideDuration={2000}>
      <Alert severity={alertType}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
