import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import SearchForm from "../components/SearchForm";
import ImageGallery from "../components/ImageGallery";
import axios from "axios";
import HeroClean from "../components/HeroClean";
import Stack from "@mui/material/Stack";
import ColorTitle from "../components/ColorTitle";
import Typography from "@mui/material/Typography";
import CopyText from "../components/CopyText";
import FAQ from "../components/FAQ";
import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import configUrl from "../config/configUrl";
// import CategoryFilter from "../components/CategoryFilter";

export default function FindMichi() {
  const [results, setResults] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({query: "", categories: ""});
  

  // useEffect(() =>{
  //   const query = searchParams.get("query")
  //   handleSearch(query);
  // }, []);
  
  const handleSearch = async (query) => {
    console.log("handleSearch query", query)
    try {
      const url = `${configUrl.apiUrl}/api/search?query=${query}`;
      const response = await axios.get(url);
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    const query = searchParams.get("query")
    handleSearch(query);
  }, [searchParams]);


  return (
    <Box>
      <HeroClean
        title1="Find Any"
        title2="Michi"
        pageDescrip="There is a michi for everyone."
      />
      <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <SearchForm
          onSearch={handleSearch}
        />
        <ImageGallery images={results} updateImages={setResults} />
      </Container>
      
      <Box sx={{ bgcolor: "background.default", alignItems: "center", alignContent: "center" }}>
        <Stack spacing={2} sx={{ alignItems: "center", marginBottom: "20px", mt: "40px" }}>
          <ColorTitle text1="Did you like it?" text2="Help the project!" />
          <Typography sx={{ textAlign: "center", color: "text.secondary", fontSize: "1.3em", width: { xs: "90%", md: "50%" } }}>
            Every donation to the{" "}
            <Typography component="span" sx={{ textAlign: "center", color: "primary.main", fontSize: "1em" }}>
              Book of Michi
            </Typography>{" "}
            project will be converted into{" "}
            <Typography component="span" sx={{ textAlign: "center", color: "primary.main", fontSize: "1em" }}>
              $michi
            </Typography>{" "}
            and used to pay for server costs and other expenses.
          </Typography>
          <CopyText text="6gve5AoHFgYru1Ko37EQ1iNi5gfULZEWqHUd7TF3FBGU" />
        </Stack>
        <Divider />
        <FAQ />
        <Divider />
      </Box>
    </Box>
  );
}
