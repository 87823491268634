import React, { useEffect, useState } from "react";
import axios from "axios";
import configUrl from "../config/configUrl";
import ImageGallery from "./ImageGallery";

const RecentUploads = () => {
  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    const fetchUploads = async () => {
      try {
        const response = await axios.get(`${configUrl.apiUrl}/api/recent-uploads`);
        const data = response.data.map((upload) => ({
          ...upload,
        }));
        setUploads(data.slice(0, 12));
      } catch (error) {
        console.error("Error fetching recent uploads:", error);
      }
    };

    fetchUploads();
  }, []);

  return <ImageGallery images={uploads} updateImages={setUploads} />;
};

export default RecentUploads;
