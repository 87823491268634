import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hero from "../components/Hero";
import FAQ from "../components/FAQ";

export default function LandingPage({userStatus}) {
  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <Hero userStatus={userStatus} />
      <Divider />
      <FAQ />
      <Divider />
    </Box>
  );
}
