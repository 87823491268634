import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TwitterIcon from "@mui/icons-material/Twitter"; // Import the correct icon
import Button from "@mui/material/Button"; // Import Button from MUI
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"; // Import KeyboardArrowDownIcon
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; // Import KeyboardArrowUpIcon
import axios from "axios";
import configUrl from "../config/configUrl";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"Copyright © $michibook Dev"}&nbsp;&nbsp;
      <Link href="https://x.com/xdmt_btc">xDMT&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer2({catList}) {
  const [categories, setCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false); // State to control category expansion

  useEffect(() => {
    // const fetchCategories = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${configUrl.apiUrl}/api/category-counts`
    //     );
    //     console.log("foter2 usou /-api/category-counts");
    //     const categoryList = Object.keys(response.data);
    //     setCategories(categoryList);
    //   } catch (error) {
    //     console.error("Error fetching categories:", error);
    //   }
    // };

    // fetchCategories();

     setCategories(catList);
  });

  // Determine categories to display based on showAllCategories state
  const displayedCategories = showAllCategories
    ? categories
    : categories.slice(0, 5);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 4 },
        py: { xs: 4, sm: 4 },
        px: { xs: 4, sm: 6 },
        backgroundColor: "background.default",
        color: "text.primary",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          px: { xs: 0, md: 10 },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "20%" },
            justifyContent: "center",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`${configUrl.apiUrl}/MICHIBOOK_LOGO_FULLL_TEXTOMAIORx400.png`}
                alt="logo michibook"
                loading="lazy"
                style={{ maxHeight: "150px" }}
              />
            </Box>
            <Typography
              textAlign={"center"}
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              Made by community for the infinity meme cat
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "top",
            alignContent: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: { xs: 2, sm: 0 },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              alignItems: "left",
              mr: 10,
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              variant="h3"
              color={"primary.main"}
              sx={{ fontWeight: "700", fontSize: "1.5em" }}
            >
              Michinu
            </Typography>
            <Link color="text.secondary" variant="body2" href="/search">
              Find a michi
            </Link>
            <Link color="text.secondary" variant="body2" href="/suggestions">
              Suggestions
            </Link>
            <Link color="text.secondary" variant="body2" href="/requests">
              Give a Michi Idea
            </Link>
            <Link color="text.secondary" variant="body2" href="/michigories">
              Categories
            </Link>
            <Link color="text.secondary" variant="body2" href="/roadmap">
              Our Roadmap
            </Link>
            <Link color="text.secondary" variant="body2" href="/user-page">
              User Page
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              alignItems: "left",
              mr: 10,
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              variant="h3"
              color={"primary.main"}
              sx={{ fontWeight: "700", fontSize: "1.5em" }}
            >
              Michigories
            </Typography>
            {displayedCategories.map((category) => (
              <Link
                key={category}
                color="text.secondary"
                variant="body2"
                href={`/category/${encodeURIComponent(category)}`}
              >
                {category}
              </Link>
            ))}
            {categories.length > 5 && (
              <Button
                variant="text"
                onClick={() => setShowAllCategories(!showAllCategories)}
                sx={{ mt: 1, textTransform: "none" }}
                startIcon={
                  showAllCategories ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                {showAllCategories ? "Show Less" : "Show All"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between", sm: "space-evenly" },
          pt: { xs: 4, sm: 4 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            href="https://x.com/xdmt_btc"
            aria-label="Twitter"
            sx={{ alignSelf: "center" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
