import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Divider, Typography } from '@mui/material';


const CategorySelector = ({ categories, onCategoryChange }) => {
  const categoryList = [
    'Animation', 'Abstract', 'Brand', 'Cartoon Character', 'Celebrities', 'Costume', 
    'Crypto Influencer', 'Manga/Anime', 'Meme References', 'POP Culture', 'OldSchool' ,
    'Personal michi', 'Singer', 'Sport', 'TV Series/Movies', 'Videogame', 'Altered Background', 'NFT References','Emotes', 'Bullish', 'Bearish'
  ];

  const sortedCategoryList = categoryList.sort((a, b) => {
    if (a.length === b.length) {
      return a.localeCompare(b);
    }
    return a.length - b.length;
  });

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .MuiToggleButton-root`]: {
      margin: theme.spacing(0.5),
      border: 10,
      borderRadius: 50,
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  }));

  return (
    <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <FormLabel component="legend" sx={{pt: '10px', fontSize:'1.3em'}}>Select Categories</FormLabel>
      <Typography variant="body2" color='info.main' sx={{pb: '10px', fontSize:'0.8em'}}>Select one or more*</Typography>
      <Divider/>
        <StyledToggleButtonGroup
          value={categories}
          onChange={(event, newCategories) => {
            // console.log("Selected Categories:", newCategories); // Adicionado para verificar as categorias selecionadas
            onCategoryChange(newCategories);
          }}
          aria-label="category selection"
          sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
        >
          {sortedCategoryList.map((category) => (
            <ToggleButton key={category} value={category} aria-label={category}>
              {category}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>

    </FormControl>
    </Paper>
  );
}

export default CategorySelector;
