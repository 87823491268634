import React from 'react';
import TextField from '@mui/material/TextField';

const DescriptionField = ({ description, onDescriptionChange }) => {
  return (
    <TextField
      style={{ whiteSpace: 'pre-line' }}
      id="michi-description"
      required
      label="Description"
      placeholder="Provide a detailed description in Good English of the Michi you are uploading."
      multiline
      rows={4}
      value={description}
      onChange={onDescriptionChange}
      variant="outlined"
      sx={{ '& .MuiOutlinedInput-root': { alignItems: 'flex-start', minHeight: '100px' } }}
    />
  );
};

export default DescriptionField;
