// src/pages/RequestsPage.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import axios from "axios";
import RequestForm from "../components/RequestForm";
import RequestList from "../components/RequestList";
import HeroClean from "../components/HeroClean";
import ColorTitle from "../components/ColorTitle";
import configUrl from "../config/configUrl";

const Requests = ({userStatus}) => {
  const [requests, setRequests] = useState([]);
  // const [recentRequests, setRecentRequests] = useState([]);
  const [view, setView] = useState("popular");
  const [limit, setLimit] = useState(10); // Inicialmente exibindo 10 sugestões

  const fetchRequests = async () => {
    try {
      const response = await axios.get(`${configUrl.apiUrl}/api/requests`);
      console.log("response.data: ", response.data)
      setRequests(response.data);
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  };

  useEffect(() => {
    fetchRequests();
    // fetchRecentRequests();
  }, []);

  // const handleVote = () => {
  //   fetchRequests();
  //   fetchRecentRequests();
  // };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const showMoreRequests = () => {
    setLimit((prevLimit) => prevLimit + 10); // Aumenta o limite em 10
  };

  return (
    <Box>
      <HeroClean
        title1="Submit a"
        title2="Michi Idea"
        pageDescrip={"Send here your idea, give details and a reference URL (if you want). Our makers will put your idea in the queue!"}
      />
      <Container
        sx={{
          display: "box",
          flexFlow: "column",
          justifyContent: "center",
          width: { xs: "100%", md: "50%" },
          alignItems: "center",
          mt: 4,
        }}
      >
        <RequestForm userData={userStatus} />
      </Container>
      <Container
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          width: { xs: "100%", md: "80%" },
          alignItems: "center",
          mt: 4,
        }}
      >
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="suggestion view"
          sx={{ mb: 4 }}
        >
          <ToggleButton value="popular" aria-label="most popular">
            Queue List
          </ToggleButton>
          <ToggleButton value="recent" aria-label="most recent">
            Done List
          </ToggleButton>
        </ToggleButtonGroup>
        {view === "popular" && (
          <>
            <ColorTitle
              text1={"Queue"}
              text2={"List"}
              fontS={1.8}
              CompH="h2"
            />
            <RequestList
              requests={requests.slice(0, limit)}
            // onVote={handleVote}
            fetchRequests={fetchRequests} 
            />
          </>
        )}
        {view === "recent" && (
          <>
            <ColorTitle
              text1={"Done"}
              text2={"List"}
              fontS={1.8}
              CompH="h2"
            />
            <RequestList
              requests={requests.slice(0, limit)}
            // requests={recentRequests.slice(0, limit)}
            // onVote={handleVote}
            fetchRequests={fetchRequests}
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={showMoreRequests}
          sx={{ mt: 2 }}
        >
          Show More
        </Button>
        <Divider sx={{ my: 4 }} />
      </Container>
    </Box>
  );
};

export default Requests;
