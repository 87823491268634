import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CopyText from "./CopyText";
import ColorTitle from "./ColorTitle";
import { styled } from "@mui/material/styles";
import UploadBox from "./UploadBox";
import RecentUploads from "./RecentUploads";
import SearchForm from "./SearchForm";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import BoxCount from "./BoxCount";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: { xs: "100%", lg: "70%" },
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "1px solid",
  boxShadow: "0 0 12px 8px hsl(26, 53%, 90%)",
  outlineColor: "hsla(220, 25%, 80%, 0.5)",
  backgroundSize: "cover",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.up("lg")]: {
    width: "70%",
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsl(26, 53%, 10%)",
    outlineColor: "hsla(26, 100%, 80%, 0.1)",
  }),
}));

const StyledBox2 = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: { xs: "100%", lg: "70%" },
  minWidth: { xs: "100%", lg: "70%" },
  marginTop: theme.spacing(8),
  // marginBottom: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "1px solid",
  // boxShadow: "0 0 12px 8px hsl(26, 53%, 90%)",
  outlineColor: "hsla(220, 25%, 80%, 0.5)",
  backgroundSize: "cover",
  [theme.breakpoints.up("xs")]: {
    marginTop: theme.spacing(10),
    width: "90%"
  },
  [theme.breakpoints.up("md")]: {
    width: "70%",
  },
  ...theme.applyStyles("dark", {
    // boxShadow: "0 0 24px 12px hsl(26, 53%, 10%)",
    outlineColor: "hsla(26, 100%, 80%, 0.1)",
  }),
}));

export default function Hero({userStatus}) {
  const navigate = useNavigate();
  const handleSearch = (query) => {
    navigate(`/find-michi?query=${query}`);
  };

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 300px at 50% -60px, hsl(26, 53%, 70%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 300px at 50% -60px, hsl(26, 53%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
        >
          <ColorTitle text1={"The Complete Book of"} text2={"$michi"} CompH="h1"/>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            <Typography component="span" color="text.primary">Gmichi fam!</Typography> Here you have a place where you can find the perfect michi for every situation.
            Upload your creation or explore the michis already made.
          </Typography>
        </Stack>
        <SearchForm onSearch={handleSearch} />
        <StyledBox2>
        <BoxCount />
        </StyledBox2>
        <StyledBox>
          <UploadBox userStatus={userStatus} />
        </StyledBox>
        <Stack
          spacing={2}
          sx={{
            alignItems: "center",
            marginBottom: "40px",
            mt: "40px",
          }}
        >
          {" "}
          <ColorTitle text1="Did you like it?" text2="Help the project!" />
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              fontSize: "1.3em",
              width: { xs: "90%", md: "60%" },
            }}
          >
            Every donation to the{" "}
            <Typography
              component="span"
              sx={{
                textAlign: "center",
                color: "primary.main",
                fontSize: "1em",
              }}
            >
              Book of Michi
            </Typography>{" "}
            project will be converted into{" "}
            <Typography
              component="span"
              sx={{
                textAlign: "center",
                color: "primary.main",
                fontSize: "1em",
              }}
            >
              $michi
            </Typography>{" "}
            and used to pay for server costs and other expenses.
          </Typography>
          <CopyText text="6gve5AoHFgYru1Ko37EQ1iNi5gfULZEWqHUd7TF3FBGU" />
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <Box marginTop={"40px"}>
          <ColorTitle text1="Recent" text2="Uploads" />
          <RecentUploads />
        </Box>
      </Container>
    </Box>
  );
}
