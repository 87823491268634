import React, { createContext, useState } from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('info');

  const showAlert = (message, type = 'info') => {
    setAlertMessage(message);
    setAlertType(type);
    setIsAlertOpen(true);
    setTimeout(() => {
      setIsAlertOpen(false);
    }, 2500); // Hide after 2 seconds
  };

  return (
    <AlertContext.Provider value={{ alertMessage, isAlertOpen, alertType, showAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
