import React from "react"; // Importa a biblioteca React
import { Box, Typography, IconButton} from "@mui/material"; // Importa componentes da biblioteca @mui/material
// import ThumbUpIcon from "@mui/icons-material/ThumbUp"; // Importa o ícone de "curtir" preenchido
// import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined"; // Importa o ícone de "curtir" contornado
import DeleteIcon from "@mui/icons-material/Delete"; // Importa o ícone de "excluir"
import axios from "axios"; // Importa a biblioteca axios para fazer requisições HTTP
import configUrl from "../config/configUrl"; // Importa a URL de configuração da aplicação


const RequestList = ({ requests, user, onVote, fetchRequests }) => {
  // Define o estado local para armazenar as sugestões curtidas
  // const [likedRequests, setLikedRequests] = React.useState([]);

  // Função assíncrona para lidar com a exclusão de sugestões
  const handleDelete = async (id) => {
    try {
      // Faz uma requisição DELETE para excluir a sugestão com o id fornecido
      await axios.delete(`${configUrl.apiUrl}/api/requests/${id}`);
      // Atualiza a lista de sugestões após a exclusão
      fetchRequests();
    } catch (error) {
      // Loga o erro no console em caso de falha na exclusão
      console.error("Error deleting request:", error);
    }
  };

  // // Função assíncrona para lidar com a votação (curtir/descurtir) de sugestões
  // const handleVote = async (id) => {
  //   // Verifica se a sugestão já foi curtida
  //   if (likedRequests.includes(id)) {
  //     // Se já foi curtida, faz uma requisição POST para remover a curtida
  //     try {
  //       await axios.post(`${configUrl.apiUrl}/api/requests/${id}/unvote`);
  //       // Atualiza o estado removendo a sugestão curtida
  //       setLikedRequests((prevLikedRequest) =>
  //         prevLikedRequest.filter((requestId) => requestId !== id)
  //       );
  //       onVote(); // Chama a função onVote para atualizar a lista de sugestões
  //     } catch (error) {
  //       // Loga o erro no console em caso de falha ao remover a curtida
  //       console.error("Error decrementing vote count:", error);
  //     }
  //   } else {
  //     // Se não foi curtida, faz uma requisição POST para adicionar a curtida
  //     try {
  //       await axios.post(`${configUrl.apiUrl}/api/requests/${id}/vote`);
  //       // Atualiza o estado adicionando a sugestão curtida
  //       setLikedRequests((prevLikedRequest) => [
  //         ...prevLikedRequest,
  //         id,
  //       ]);
  //       onVote(); // Chama a função onVote para atualizar a lista de sugestões
  //     } catch (error) {
  //       // Loga o erro no console em caso de falha ao adicionar a curtida
  //       console.error("Error incrementing vote count:", error);
  //     }
  //   }
  // };

  // Renderiza a lista de sugestões
  return (
    <Box sx={{ width: { xs: "100%", sm: "80%" }, mt: 4 }}>
      {requests.map((request) => (
        <Box
          key={request.id} // Define a chave única para cada sugestão
          sx={{ mb: 2, p: 2, borderBottom: "1px solid #ccc", borderLeft: "1px solid #ccc", borderRadius: 5, width: "100%" }}
        >
          <Box sx={{ display: "flex", justifyContent: 'space-between', borderBottom: "1px solid #ccc" }}>

            <Typography variant="body2" sx={{ mr: 2 }}>
              {`User ID (${request.user_id}) | Idea: `}{request.idea}</Typography> {/* Texto da sugestão */}
          </Box>
          <Box sx={{ display: "flex", alignItems: "top", justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="h6" fontSize="0.8em" color="primary.main" paddingRight={"10px"} >
              Reference URL: {request.url}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "space-evenly" }}>
              <IconButton
                size="small"
                color="error"
                onClick={() => handleDelete(request.id)} // Função de exclusão ao clicar no ícone de lixeira
              >
                <DeleteIcon /> 
              </IconButton> 
            </Box>
            
            {/* <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "space-evenly" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => handleVote(request.id)}>
                  {likedRequests.includes(request.id) ? (
                    <ThumbUpIcon /> // Ícone de curtido
                  ) : (
                    <ThumbUpAltOutlinedIcon /> // Ícone de não curtido
                  )}
                </IconButton>
                <Typography variant="body2">{request.votes}</Typography>
              </Box>
            </Box> */}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RequestList;
