// Passo 1: Criar um Contexto de Autenticação
// Primeiro, crie um contexto para gerenciar o estado de autenticação do usuário.

import React, { createContext, useState, useEffect } from 'react';

// Cria o contexto de autenticação
export const AuthContext = createContext();

// Componente provedor de autenticação
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Aqui você pode verificar a sessão do usuário, por exemplo, fazendo uma requisição para uma API
    const checkAuth = async () => {
      // Simula uma chamada à API para verificar a sessão do usuário
      const response = await fetch('/api/user');
      const result = await response.json();
      setIsAuthenticated(result.isAuthenticated);
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
