import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, CardMedia, Tooltip } from '@mui/material';
import axios from 'axios';
import configUrl from "../config/configUrl";

const MichiList = React.memo(({ author }) => {
    const [michis, setMichis] = useState([]);

    const handleDownload = async (fileName, id) => {
        const link = document.createElement("a");
        link.href = `${configUrl.apiUrl}/uploads/${fileName}`;
        link.target = "_blank";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        try {
            await axios.post(`${configUrl.apiUrl}/api/increment-download`, { id });
        } catch (error) {
            console.error("Error incrementing download count:", error);
        }
    };

    useEffect(() => {
        // Substitua pela URL correta do seu backend
        const userID = author?.userId;
        axios.get(`${configUrl.apiUrl}/api/user/michis`)
            .then(response => {
                if (author !== "all") {
                    handleMichiFilter(response.data);
                } else {
                    setMichis(response.data);
                }
            })
            .catch(error => console.error('Error fetching Michis:', error));

        const handleMichiFilter = (data) => {
            // Filtra o array para incluir apenas os objetos onde data.user é igual ao userID
            const filteredData = data.filter(item => item.author == userID);
            // Atualiza o estado com os dados filtrados
            setMichis(filteredData);
        };
    }, [author]);

    const isVideo = (fileName) => {
        const videoExtensions = [".mp4", ".webm", ".ogg"];
        return videoExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
    };

    console.log(author)

    return (
        <Box>
            {michis.map(michi => (
                <Card key={michi.id} sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, mb: 2 }}>
                    <CardMedia
                        component={isVideo(michi.file_name) ? "video" : "img"}
                        sx={{ width: 100, height: 100, objectFit: 'cover' }}
                        image={`${configUrl.apiUrl}/uploads/${michi.file_name}`} // Substitua pelo campo correto da imagem
                        alt={michi.description}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent>
                            <Typography variant="body1" fontSize={"0.8em"} color="primary.main">{michi.categories}</Typography>
                            <Typography variant="body2">{michi.description}</Typography>
                            <Typography variant="body1" fontSize={"0.7em"} color="primary.main">id: {michi.id}</Typography>
                            <Typography variant="body1" fontSize={"0.7em"} color="primary.main">likes: {michi.likes}</Typography>
                        </CardContent>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 1 }}>
                        <Button variant="contained" color="primary" sx={{ mb: 1 }} size="small"
                            style={{ color: "white" }}
                            onClick={() => handleDownload(michi.file_name, michi.id)}>
                            Download
                        </Button>
                        <Tooltip title="Soon" placement="top" enterDelay={0}>
                            <span style={{ width: "100%" }}>
                                <Button disabled variant="outlined" color="secondary" sx={{ width: "100%" }}>
                                    Edit {"(soon)"}
                                </Button>
                            </span>
                        </Tooltip>
                        { author === "master" ?
                        <Tooltip title="Soon" placement="top" enterDelay={0}>
                            <span style={{ width: "100%" }}>
                                <Button disabled variant="outlined" color="secondary" sx={{ width: "100%" }}>
                                    Edit {"(soon)"}
                                </Button>
                            </span>
                        </Tooltip>
                        : <></>}
                    </Box>
                </Card>
            ))}
        </Box>
    );
});

export default MichiList;
